import React from "react";
import WithSubnavigation from "../components/organisms/navigation/navigation-bar-chakra";
import SignupCard from "../components/organisms/signup-card/signup-card";
import Header from "../components/organisms/vpn-template/header";

const Register = () => {
  return (
    <div>
      <Header />
      <SignupCard />
    </div>
  );
};

export default Register;
